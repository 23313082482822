import React, { useMemo } from 'react';
import { CloudinaryImage, CloudinaryImageProps } from '@stitch-fix/mode-react';
import type { BasicCloudinaryAsset } from '../../utils/getCloudinaryItem/getCloudinaryItem';
import { parseCloudinaryUrl } from '../../utils/parseCloudinaryUrl/parseCloudinaryUrl';
import {
  CloudinaryImageV2,
  type ContentStackCloudinaryAssetProps,
} from '../CSCloudinaryAsset/Image';
import styles from './styles.module.scss';
import type { MaxContainerWidth } from '../CSCloudinaryAsset/utils';

type AspectRatio = {
  height: number;
  width: number;
};

export interface CloudinaryImageWrapperProps
  extends Omit<CloudinaryImageProps, 'baseUrl' | 'mediaPath'> {
  convertToCloudFront?: boolean;
  src: string;
  alt: string;
  aspectRatio?: AspectRatio;
  cloudinaryAsset?: BasicCloudinaryAsset;
  sizes?: ContentStackCloudinaryAssetProps['sizes'];

  /**
   * this can take an exact pixel value, but try to stick to the layout standards so they
   * can be easily changed centrally (close is "good enough" in this case)
   *
   * xl = 2000,
   * lg = 1600,
   * md = 1400,
   * sm = 1200,
   * xs = 800,
   * fullBleed = 0 / infinite
   */
  maxContainerWidth?: MaxContainerWidth;
  imageProps?: ContentStackCloudinaryAssetProps['imageProps'];
}

export type CloudinaryImageFields = Pick<
  CloudinaryImageWrapperProps,
  'alt' | 'src' | 'aspectRatio' | 'cloudinaryAsset'
>;

const formatAspectRatio = (aspectRatio?: AspectRatio) =>
  aspectRatio ? `${aspectRatio.width} / ${aspectRatio.height}` : 'auto';

const logMessage = (msg: string) => {
  // eslint-disable-next-line no-console
  console.warn(msg);
};

const CLOUDFRONT_PATH = process.env.NEXT_PUBLIC_CLOUDFRONT_PATH;

export const CloudinaryImageWrapper = React.memo(
  ({
    src,
    lazy = true,
    convertToCloudFront = true,
    sources,
    transforms,
    alt,
    objectFit,
    aspectRatio,
    fetchPriority,
    cloudinaryAsset,
    sizes,
    maxContainerWidth,
    imageProps,
  }: CloudinaryImageWrapperProps) => {
    const parsed = useMemo(() => {
      if (!src) {
        return undefined;
      }

      // We might be able to remove this part
      // it's concerned with handling placeholder images
      try {
        const parts = parseCloudinaryUrl(src);

        const testUrl = src.indexOf('bigsquare.png') !== -1;

        if (parts) {
          let baseUrl = parts.baseUrl;

          if (convertToCloudFront && CLOUDFRONT_PATH) {
            baseUrl = `https://${new URL(CLOUDFRONT_PATH).hostname}/${
              parts.basePath
            }`;
          }

          return { baseUrl, parts, testUrl };
        }

        return undefined;
      } catch (e) {
        // swallow parse errors,
        // in this case we fall back to
        // the original responsiveImage component
        logMessage(
          `Error parsing cloudinary url: ${src}, this image will not render`,
        );

        return undefined;
      }
    }, [src, convertToCloudFront]);

    if (cloudinaryAsset) {
      return (
        <CloudinaryImageV2
          alt={alt}
          cloudinaryAsset={cloudinaryAsset}
          sizes={sizes}
          maxContainerWidth={maxContainerWidth}
          imageProps={imageProps}
        />
      );
    }

    if (parsed) {
      // for now we are throwing away
      // the transforms already in the url,
      // but keeping the unsupported ones,
      // and passing sources and transforms
      // as props to the cloudinary image
      const { version, mediaPath, unsupportedTransforms, transformations } =
        parsed.parts;
      const sourcesToUse = sources || {
        sm: { w: '560' },
      };

      let correctedBaseUrl = parsed.baseUrl;
      const transformsToUse = { ...transforms };

      // test urls are a special case,
      // we will remove this once we get rid of the
      // dummy urls in percy
      if (parsed.testUrl) {
        // this is just to fix the dummy images
        // which have labels, which we correct,
        // add crops, which we preserve
        // basically the transforms we keep here are part of the url
        // we want to transform

        unsupportedTransforms.forEach((transform, i) => {
          // change background color so it's clear this is one of
          // the cloudinary-ficated images
          unsupportedTransforms[i] = transform.replace('B0C997', 'ff8b5a');
        });

        if (transformations && transformations.c === 'crop') {
          correctedBaseUrl += `/c_crop,h_${transformations.h},w_${transformations.w}`;
        }
      }

      return (
        <div
          data-testid="cloudinary-image-wrapper"
          className={styles.imageWrapper}
          style={{
            aspectRatio: formatAspectRatio(aspectRatio),
          }}
        >
          <CloudinaryImage
            baseUrl={`${correctedBaseUrl}`}
            mediaPath={`${unsupportedTransforms.join(
              '/',
            )}/${version}/${mediaPath}`}
            alt={alt}
            lazy={lazy}
            sources={sourcesToUse}
            transforms={transformsToUse}
            objectFit={objectFit}
            fetchPriority={fetchPriority}
          />
        </div>
      );
    }

    return null;
  },
);
