/* eslint-disable no-shadow */

import { CloudinaryImageProps } from '@stitch-fix/mode-react';

interface CloudinaryImage {
  protocol: string;
  host: string;
  baseUrl: string;
  basePath: string;
  transformations: CloudinaryImageProps['transforms'];
  unsupportedTransforms: string[];
  mediaPath: string;
  version?: string;
}

enum ParseState {
  BASE_URL,
  TRANSFORM,
  VERSION,
  MEDIA_PATH,
}

export const CLOUDINARY_REMOTE_FETCH_BASE_URL =
  'https://res.cloudinary.com/stitch-fix/image/fetch/' as const;

const versionRegex = /^v\d+/;
const isCloudinaryRegex =
  /^https?:\/\/([a-z0-9]{3}\.cloudinary\.com|[a-z0-9]+.cloudfront.net)\/[^/]+\/image\/upload/;
const isCloudinaryFetchRegex =
  /^https?:\/\/([a-z0-9]{3}\.cloudinary\.com|[a-z0-9]+.cloudfront.net)\/[^/]+\/image\/fetch/;
// cloudindary considers a path segement to be a tranformation
// if it contains 1-3 alphanumeric characters, followed by an underscore
// https://cloudinary.com/documentation/upload_images#including_a_path_in_the_public_id
const transformationRegex = /[a-z]{1,3}_.+/;
const supportedTransformationRegex = /^((([qcwhf]|dpr)_[^,]+),?)*$/;
const uploadRegex = /upload/;
const protocolRegex = /^https?:/;

/**
 *
 * Parses cloudinary urls to extract data, based on documentation here:
 * https://cloudinary.com/documentation/image_transformations
 *
 * @param url - a cloudinary url
 * @returns CloudinaryImage
 */
const parseCloudinaryUrl = (url: string): CloudinaryImage => {
  let parseState: ParseState = ParseState.BASE_URL;
  //   const hasVersions = versionRegex.test(url);
  const baseUrlTokens: string[] = [];
  const transformations: Set<string> = new Set();
  const mediaPathTokens: string[] = [];
  let version = '';

  if (!isCloudinaryRegex.test(url) && !isCloudinaryFetchRegex.test(url)) {
    throw new Error('Not a cloudinary url');
  }
  if (isCloudinaryFetchRegex.test(url)) {
    const mediaPath = url.split('/fetch/')[1];

    return {
      host: '',
      protocol: '',
      basePath: CLOUDINARY_REMOTE_FETCH_BASE_URL,
      baseUrl: CLOUDINARY_REMOTE_FETCH_BASE_URL,
      mediaPath,
      version: '',
      unsupportedTransforms: [],
      transformations: {},
    };
  }
  // consume tokens, init state + add to base url, if we hit a transform switch
  // to transform state,and add to transform array if we hit a version switch to
  // version state, and add to version if we hit something that is neither a
  // transform or a version, add to media path.
  //
  // If we hit a component that contains an unsupported transform then the
  // *entire component* is unsupported (otherwise the outcome will be unpredictable)
  const { protocol, hostname, pathname } = new URL(url);
  const tokens = pathname.split('/');

  while (tokens.length) {
    const token = tokens.shift();

    if (token) {
      switch (parseState) {
        case ParseState.BASE_URL:
          if (uploadRegex.test(token)) {
            baseUrlTokens.push(token);
            parseState = ParseState.TRANSFORM;
          } else if (protocolRegex.test(token)) {
            // protocol needs an extra slash
            baseUrlTokens.push(`${token}/`);
          } else {
            baseUrlTokens.push(token);
          }
          break;
        case ParseState.TRANSFORM:
          if (transformationRegex.test(token)) {
            transformations.add(token);
          } else if (versionRegex.test(token)) {
            parseState = ParseState.MEDIA_PATH;
            version = token;
          } else {
            mediaPathTokens.push(token);
            parseState = ParseState.MEDIA_PATH;
          }
          break;
        case ParseState.MEDIA_PATH:
          mediaPathTokens.push(token);
          break;
        default:
          break;
      }
    }
  }

  const transforms: CloudinaryImageProps['transforms'] = {};
  const unsupportedTransforms: string[] = [];

  transformations.forEach(transformation => {
    if (!supportedTransformationRegex.test(transformation)) {
      unsupportedTransforms.push(transformation);

      // quick exit for unsupported transforms
      return;
    }

    const split = transformation.split(',');

    split.forEach(t => {
      const [key, value] = t.split('_');

      if (key === 'w' || key === 'h') {
        transforms[key] = Number(value);
      }

      // can't use regex because of types
      else if (key === 'c' || key === 'f' || key === 'q' || key === 'dpr') {
        transforms[key] = value;
      } else if (key === 'e') {
        if (!transforms.e) {
          transforms.e = [];
        }
        if (Array.isArray(transforms.e)) {
          transforms.e.push(value);
        } else {
          transforms.e = value;
        }
      } else {
        unsupportedTransforms.push(t);
      }
    });
  });

  const ret = {
    host: hostname,
    protocol,
    basePath: baseUrlTokens.join('/'),
    baseUrl: `${protocol}//${hostname}/${baseUrlTokens.join('/')}`,
    mediaPath: mediaPathTokens.join('/'),
    version,
    unsupportedTransforms,
    transformations: transforms,
  };

  return ret;
};

export { parseCloudinaryUrl };
